@import "./../../assets/styles/variables";

nav {
  background-color: #0d1731;
  color: $white;
  border-bottom: 1px solid $lightdarkblue;
}

.navbar-brand {
  margin-right: 0;
}

.nav-link,
.nav-link:visited {
  color: $white;
  font-weight: 500;
  font-size: $medium;
  line-height: $normal;
}

.nav-link.active {
  color: $lightblue !important;
}

.nav-link:hover {
  color: $lightblue;
}

.dropdown-menu {
  background-color: $white;
}

.dropdown-item {
  color: #0d1731 !important;
  font-weight: 400;
  font-family: $dmserif;
  font-size: 18px;
  margin: 0;
  padding: 15px 20px;
  border-bottom: 1px solid $lightgrey;
}

.dropdown-item:last-of-type {
  border-bottom: none;
}

.dropdown-item:hover,
.dropdown-item:active {
  color: #355cc1 !important;
  background: none;
}

.dropdown-item .icon-menu {
  margin-right: 10px;
  padding: 5px;
  background-color: rgba(0, 148, 255, 0.2);
  max-width: 36px;
  max-height: 36px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: $white;
}
