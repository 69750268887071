.accordion-item {
  background: none;
  color: $white;
  border: none;
  border-bottom: 1px solid $lightdarkblue;
}

.accordion-button {
  background: none;
  color: $white;
  font-family: $roboto;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  outline: none;
}

.accordion-button:not(.collapsed) {
  background: none;
  color: $white;
  border-bottom: 1px solid $lightdarkblue;
  box-shadow: none;
}

// .accordion-button::after {
// }

.accordion-body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
