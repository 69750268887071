@import "./../../assets/styles/variables";

#statistics .number {
  font-family: $robotoslab;
  font-weight: 700;
  line-height: 100px;
  font-size: 80px;
  background: $gradientblue;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#statistics .number-sub {
  font-weight: 500;
  font-size: 18px;
}
