.list-group {
  border-radius: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.list-group-item {
  background: none;
  color: $white;
  border: none;
  border-bottom: 1px solid $lightdarkblue;
}

.list-group-item:last-of-type {
  border-bottom: none;
}
