/* Colors */
$black: #000000;
$darkblue2: #0d1731;
$darkblue: #050e27;
$lightdarkblue: #2b374e;
$lightblue: #319cfa;
$blue: #03519a;
$white: #ffffff;
$yellow: #fdcc00;
$grey: #d2d2d2;
$lightgrey: #e8e8e8;
$darkgrey: #3d4559;
$lightdarkgrey: #868686;

/* Gradients */
$gradientyellow: linear-gradient(
  90deg,
  rgba(253, 204, 0, 1) 0%,
  rgba(255, 217, 61, 1) 100%
);
$gradientyellowhover: linear-gradient(90deg, #e2b500 0%, #e2d85d 100%);
$gradientblue: linear-gradient(
  90deg,
  rgba(1, 134, 250, 1) 0%,
  rgba(20, 205, 254, 1) 100%
);

/* fonts */
$dmserif: "DM Serif Display", serif;
$roboto: "Roboto", sans-serif;
$robotoslab: "Roboto Slab", serif;

/* font sizes */
$extralarge: 74px;
$large: 48px;
$slightnormal: 26px;
$normalize: 24px;
$normal: 20px;
$medium: 16px;
$small: 14px;
