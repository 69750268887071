.card {
  background-color: #0d1731;
  border-radius: 0;
  display: block;
  border: 1px solid #2b374e;
  margin-bottom: 20px;
  width: 100%;
}

.card-body {
  color: $white;
  padding: 25px;
  font-size: $medium;
  line-height: $normalize;
}

.card-title {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  font-family: $dmserif;
}

.card-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

@media screen and (max-width: 991px) {
  .card-text {
    font-size: $small !important;
  }
}

.card-link {
  color: $lightblue;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.card-link ion-icon {
  margin-left: 10px;
}

.icon-card-title {
  margin-right: 10px;
  padding: 10px;
  background-color: $darkgrey;
}
