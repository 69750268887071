.button {
  text-decoration: none;
  display: inline-block;
  border: none;
  outline: none;
  border-radius: 0;
}

.button-yellow {
  background-color: $yellow;
  background-image: $gradientyellow;
  color: $black !important;
}

.button-yellow:hover {
  background-image: $gradientyellowhover;
}

.button-darkblue {
  background-color: $darkblue;
  color: $white;
}

.button-normal {
  font-size: $normal;
  font-weight: 600;
  line-height: $slightnormal;
  padding: 15px 45px;
}

@media screen and (max-width: 991px) {
  .button-normal {
    font-size: $medium !important;
  }
}

.button-medium {
  font-size: $medium;
  line-height: $normalize;
  font-weight: 600;
  padding: 10px 30px;
}

.button-small {
  font-size: $small;
  line-height: $normalize;
  font-weight: 600;
  padding: 10px 30px;
}

.btn-link {
  color: $white;
  text-decoration: none;
}
