.form-control {
  background-color: $white;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 0;
  height: 46px;
  color: $lightdarkgrey;
}

.form-control:focus {
  box-shadow: 5px 5px 0px $lightblue, 10px 10px 0px $blue;
}
