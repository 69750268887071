@import "variables";
@import "fonts";
@import "accordion";
@import "button";
@import "cards";
@import "forms";
@import "listgroup";
@import "form";

body {
  background-color: $darkblue;
  color: $white;
  font-family: $roboto;
  font-weight: 300;
  font-style: normal;
  font-size: $normal;
  line-height: $slightnormal;
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  body {
    padding-top: 70px;
  }
}

section {
  margin: $extralarge 0;
}

.heading-large {
  font-family: $dmserif;
  font-weight: 400;
  font-style: normal;
  font-size: $extralarge;
  line-height: normal;
  margin: $large 0;
}

.heading {
  font-family: $dmserif;
  font-weight: 400;
  font-style: normal;
  font-size: 54px;
  line-height: 70px;
  margin: $large 0;
}

.heading-small {
  font-family: $dmserif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 44px;
}

.heading-smaller {
  font-family: $dmserif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 40px;
}

h6 {
  font-family: $roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

@media screen and (max-width: 991px) {
  body {
    font-size: $medium !important;
  }

  .heading-large {
    font-size: $large !important;
    margin: $slightnormal 0;
  }

  .heading {
    font-size: $slightnormal !important;
    margin: $slightnormal 0;
    line-height: $large;
  }
}

.annotation {
  background-color: $blue;
  /* padding: 0 10px; */
}

/* colors */
.grey {
  color: $grey;
}

.blue {
  color: #14cdfe;
}

/* box */
.box {
  background-color: $darkblue2;
  border: 1px solid $lightdarkblue;
  padding: 30px 20px;
}
